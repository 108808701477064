.analysis-container {
    position: absolute;
    bottom: 2vw;
    left: 4vw;
    width: fit-content;
    max-width: 100%; /* Ensure container doesn't exceed viewport width */
    height: fit-content;
    background-color: rgba(255, 255, 255, 0.95);
    border-radius: 8px;
    padding: 10px;
    box-shadow: 0px 0px 15px rgba(12, 11, 9, 0.466);
    z-index: 1001;
    display: grid;
    grid-template-columns: repeat(4, max-content);
    justify-content: space-evenly;
    font-size: 20px;
    row-gap: 10px;
    column-gap: 10px;
}
.analysis-container:empty {
    display: none;
}
.analysis-container > div:nth-child(n+5) {
    background-color: rgba(173, 173, 173, 0.247);
    border-radius: 8px;
    padding: 5px;
}
.analysis-title {
    text-decoration: underline;
    background-color: rgba(199, 122, 59, 0.288);
    border-radius: 8px;
    font-size: 20px;
    padding: 1vw;
}

/* Media query for smaller screens (e.g., smartphones) */
@media (max-width: 850px) {
    .analysis-container {
        position: fixed;
        justify-self:auto;
        left:5px;
        box-sizing: border-box;
        font-size: small;
        height: auto;
        width: calc(100% - 10px);
        border-radius: 10px;
        padding: 8px;
        gap: 4px;
        bottom: 40px;
        word-wrap: break-word;
        white-space: normal;
        grid-template-columns: repeat(4, 1fr);
    }
    .analysis-container > div:nth-child(n+5) {
        box-sizing: border-box;
        padding-right: 4px;
        padding-left: 2px;
    }
    .analysis-title {
        box-sizing: border-box;
        font-size: small;
        border-radius: 3px;
        padding-right: 4px;
        padding-left: 2px;
    
    }
}



