body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/*TODO MAKE ICON THAT HOLDS MARKER */
/* #map {
  cursor: url('../public/marker-icon.png'), auto;
} */


.loader-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex; 
  justify-content: center; 
  align-items: center; 
  background-color: rgba(0, 0, 0, 0.5); 
  z-index: 1000;
}

/* Add this to your CSS file (e.g., App.css) */
.chatbox {
  position: fixed; /* Fixed position to appear at the same spot regardless of scrolling */
  bottom: 20px; /* Position it at the bottom right corner */
  right: 20px;
  background-color: rgba(255, 255, 255, 0.9); /* Semi-transparent white */
  padding: 10px 20px;
  border-radius: 20px; /* Rounded corners */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Soft shadow for depth */
  font-family: Arial, sans-serif; /* Optional: font styling */
  z-index: 100; /* Ensure it's above other content */
}
