.leaflet-container {
  height: 100%;
  width: 100%;
}

.map-container {
  position:fixed;
  height: 100vh;
  width: 100vw;
}

#map {
  height: 100%;
  width: 100%;
}

.powered-by-container{
  position: absolute;
  bottom: 0px;
  left: 0px;  
  z-index: 1000;
  background-color: #ffffffc4;
  font-size: small;
  color: rgba(0, 0, 0, 0.644);
  padding-bottom: 1px;
  padding-left: 1px;
  padding-right: 1px;
}
.poweredBy-image {  
  position: relative;
  bottom: -2px;
  width: 80px;   
  height: auto;
}


.user-location-button{
  position: absolute;
  top: 40px;
  margin-top: 10px;
  margin-right: 10px;
  right: 0px;
  width: auto;   
  height: auto;
  z-index: 1000;
  padding: 0;
  border-width: 2px;
  border-radius: 5px;
  border-color: rgba(0, 0, 0, 0.4);
  background-color: #ffffff;
}
.user-location-button:hover{
  background-color: rgb(226, 226, 226)
}
.user_position_img {
  margin-top: 2px;
  height: auto;
  width: 26px;
  padding: 2px;
}

.position-button {
  position: absolute;
  bottom: 2vw;          /* Adjust as needed for desired vertical position */
  right: 4vw;         /* Adjust as needed for desired horizontal position */
  background-color: #2eb44bcc;
  color: rgb(236, 228, 228);
  padding: 1% 2%;
  border-radius: 100px;
  cursor: not-allowed;  /* Default cursor; change with state as needed */
  z-index: 1000;
  font-size: clamp(1.5vw, 3vw, 50px);
  width: 30vw;
  max-width: 500px;
  height: 8vw; 
  max-height: 130px;
  border-color: black;
  border-width: 0.5vw;
  }

.loader_inside_button {
  display: block;
  margin: auto;
  width: 0.5rem;
  height: 0.5rem;
  font-size: 0.5vw;
  border-radius: 50%;
  position: relative;
  text-indent: -9999em;
  animation: mulShdSpin 1.1s infinite ease;
  transform: translateZ(0);
}
@keyframes mulShdSpin {
  0%,
  100% {
    box-shadow: 0em -2.6em 0em 0em #ffffff, 1.8em -1.8em 0 0em rgba(255,255,255, 0.2), 2.5em 0em 0 0em rgba(255,255,255, 0.2), 1.75em 1.75em 0 0em rgba(255,255,255, 0.2), 0em 2.5em 0 0em rgba(255,255,255, 0.2), -1.8em 1.8em 0 0em rgba(255,255,255, 0.2), -2.6em 0em 0 0em rgba(255,255,255, 0.5), -1.8em -1.8em 0 0em rgba(255,255,255, 0.7);
  }
  12.5% {
    box-shadow: 0em -2.6em 0em 0em rgba(255,255,255, 0.7), 1.8em -1.8em 0 0em #ffffff, 2.5em 0em 0 0em rgba(255,255,255, 0.2), 1.75em 1.75em 0 0em rgba(255,255,255, 0.2), 0em 2.5em 0 0em rgba(255,255,255, 0.2), -1.8em 1.8em 0 0em rgba(255,255,255, 0.2), -2.6em 0em 0 0em rgba(255,255,255, 0.2), -1.8em -1.8em 0 0em rgba(255,255,255, 0.5);
  }
  25% {
    box-shadow: 0em -2.6em 0em 0em rgba(255,255,255, 0.5), 1.8em -1.8em 0 0em rgba(255,255,255, 0.7), 2.5em 0em 0 0em #ffffff, 1.75em 1.75em 0 0em rgba(255,255,255, 0.2), 0em 2.5em 0 0em rgba(255,255,255, 0.2), -1.8em 1.8em 0 0em rgba(255,255,255, 0.2), -2.6em 0em 0 0em rgba(255,255,255, 0.2), -1.8em -1.8em 0 0em rgba(255,255,255, 0.2);
  }
  37.5% {
    box-shadow: 0em -2.6em 0em 0em rgba(255,255,255, 0.2), 1.8em -1.8em 0 0em rgba(255,255,255, 0.5), 2.5em 0em 0 0em rgba(255,255,255, 0.7), 1.75em 1.75em 0 0em #ffffff, 0em 2.5em 0 0em rgba(255,255,255, 0.2), -1.8em 1.8em 0 0em rgba(255,255,255, 0.2), -2.6em 0em 0 0em rgba(255,255,255, 0.2), -1.8em -1.8em 0 0em rgba(255,255,255, 0.2);
  }
  50% {
    box-shadow: 0em -2.6em 0em 0em rgba(255,255,255, 0.2), 1.8em -1.8em 0 0em rgba(255,255,255, 0.2), 2.5em 0em 0 0em rgba(255,255,255, 0.5), 1.75em 1.75em 0 0em rgba(255,255,255, 0.7), 0em 2.5em 0 0em #ffffff, -1.8em 1.8em 0 0em rgba(255,255,255, 0.2), -2.6em 0em 0 0em rgba(255,255,255, 0.2), -1.8em -1.8em 0 0em rgba(255,255,255, 0.2);
  }
  62.5% {
    box-shadow: 0em -2.6em 0em 0em rgba(255,255,255, 0.2), 1.8em -1.8em 0 0em rgba(255,255,255, 0.2), 2.5em 0em 0 0em rgba(255,255,255, 0.2), 1.75em 1.75em 0 0em rgba(255,255,255, 0.5), 0em 2.5em 0 0em rgba(255,255,255, 0.7), -1.8em 1.8em 0 0em #ffffff, -2.6em 0em 0 0em rgba(255,255,255, 0.2), -1.8em -1.8em 0 0em rgba(255,255,255, 0.2);
  }
  75% {
    box-shadow: 0em -2.6em 0em 0em rgba(255,255,255, 0.2), 1.8em -1.8em 0 0em rgba(255,255,255, 0.2), 2.5em 0em 0 0em rgba(255,255,255, 0.2), 1.75em 1.75em 0 0em rgba(255,255,255, 0.2), 0em 2.5em 0 0em rgba(255,255,255, 0.5), -1.8em 1.8em 0 0em rgba(255,255,255, 0.7), -2.6em 0em 0 0em #ffffff, -1.8em -1.8em 0 0em rgba(255,255,255, 0.2);
  }
  87.5% {
    box-shadow: 0em -2.6em 0em 0em rgba(255,255,255, 0.2), 1.8em -1.8em 0 0em rgba(255,255,255, 0.2), 2.5em 0em 0 0em rgba(255,255,255, 0.2), 1.75em 1.75em 0 0em rgba(255,255,255, 0.2), 0em 2.5em 0 0em rgba(255,255,255, 0.2), -1.8em 1.8em 0 0em rgba(255,255,255, 0.5), -2.6em 0em 0 0em rgba(255,255,255, 0.7), -1.8em -1.8em 0 0em #ffffff;
  }
}

@media (max-width: 850px) {
  .powered-by-container {
    position: fixed;
    bottom: 0;
    left: 0px;
    font-size:x-small;
  }
  .poweredBy-image {  
    padding-top: 1px;
    width: 60px;   
  }
  .user-location-button{
    border-radius: 4px;
  }
  .position-button{
    position: fixed;
    top: 10px;
    right: 30vw;
    font-size: 3.5vw;
    width: 40vw;
    height: 10vw; 
  }
}

@media print {
  /* Ensure the map takes the full available space during print */
  .map-container {
    position: relative; /* Change this for printing */
    width: 110%;
    height: 110%;
  }
}
